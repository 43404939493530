<script lang="ts" setup="">
  import RouletteSlider from '~/components/sliders/roulette/RouletteSlider.vue';
  import { getNoveltyMovies } from '~/services/modules/movies.service';

  const { data, isFetching, suspense } = getNoveltyMovies({ params: { ordering: 'popularity' } });

  onServerPrefetch(async () => await suspense());
</script>

<template>
  <v-wrapper
    section
    class="popular full-width"
    title="Популярное"
    remove-padding
    :link="{ name: 'Смотреть все', route: { name: 'browse-category', params: { category: 'popular' } } }"
  >
    <roulette-slider
      appendClass="popular__slider content-right"
      :items="data ?? []"
      :is-fetching="isFetching"
      skeleton
    />
  </v-wrapper>
</template>

<style lang="scss" scoped>
  .popular {
    padding: 56px 0 0;
    @media (max-width: $retina) {
      padding: 24px 0;
    }
  }
</style>
